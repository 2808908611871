
import { Options, Vue } from 'vue-class-component'
import { PropSync } from 'vue-property-decorator'
import TWImportView from '@/views/tradeWaltz/TWImportView.vue'
import { FETCH_ALL_TW_PROCESS } from '@/store/actions'
import errorHandler from '@/utils/errorHandler'
import { ITWProcess } from 'smartbarcode-web-core/src/utils/types'
@Options({
  components: {
    TWImportView,
  },
  name: 'TradeWaltzViewDialog',
  emits: ['update:isShowDialog', 'update:closed'],
  inheritAttrs: false,
})
export default class TradeWaltzViewDialog extends Vue {
  @PropSync('isShowDialog', { type: Boolean }) _isShowDialog!: boolean
  isLoading = false

  async created() {
    try {
      const processesData: ITWProcess[] = this.$store.state.tradeWaltz.processesData
      if (!processesData || processesData.length < 1) {
        this.isLoading = true
      }
      await this.$store.dispatch(FETCH_ALL_TW_PROCESS)
    } catch (error) {
      errorHandler(error)
    } finally {
      this.isLoading = false
    }
  }
}
