<template>
  <div>
    <TWBindingDialogComponent
      v-if="_isShowDialog"
      :title="`${$t('tradewaltz.store_bl')}`"
      v-model:isShowDialog="_isShowDialog"
      :tradeWaltzStoreKey="tradeWaltzStoreKey"
      :TWSelectionOptions="twBLBindingOptions"
    />
  </div>
  <div />
</template>
<script lang="ts">
import SelectAutocomplete from '@/components/common/SelectAutocomplete.vue'
import { Options, Vue } from 'vue-class-component'
import TreeSelectComponent from '@/components/baseComponent/TreeSelectComponent.vue'
import TWBindingDialogComponent from '../TWBindingDialogComponent.vue'
import { PropSync } from 'vue-property-decorator'
import { getTWBLLinkageGoodsLineBindingOption } from '@/utils/helpers'
@Options({
  components: {
    SelectAutocomplete,
    TreeSelectComponent,
    TWBindingDialogComponent,
  },
  name: 'TradeWaltzPOBindingDialogComponent',
  emits: ['update:isShowDialog'],
  inheritAttrs: false,
})
export default class TradeWaltzPOBindingDialogComponent extends Vue {
  @PropSync('isShowDialog', { type: Boolean }) _isShowDialog!: boolean
  tradeWaltzStoreKey = 'storeBLBindingSettings'

  get twBLBindingOptions() {
    return getTWBLLinkageGoodsLineBindingOption()
  }
}
</script>
<style lang="scss" scoped>
::v-deep {
  .el-dialog {
    max-width: 50% !important;
  }
}

.control-button {
  left: 50%;
  bottom: 0%;
  transform: translate(-50%, 50%);
}
</style>
