
import { Vue, Options } from 'vue-class-component'
import PurchaseOrderView from './tabs/PurchaseOrderView.vue'
import TWPOMainComponent from './components/PO/TWPOMainComponent.vue'
import TWRequestBookingMainComponent from './components/RequestBooking/TWRequestBookingMainComponent.vue'
import TWStoreBLMainComponent from './components/StoreBL/TWStoreBLMainComponent.vue'
import { SET_DISPLAY_PAGE } from '@/store/actions'

const enum ETradeWaltzPage {
  PURCHASE_ORDER_TAB = 'purchaseOrderTab',
  BOOKING_REQ_TAB = 'bookingReqTab',
  STORE_BL_REQ_TAB = 'storeBLReqTab',
}

@Options({
  components: {
    PurchaseOrderView,
    TWPOMainComponent,
    TWRequestBookingMainComponent,
    TWStoreBLMainComponent,
  },
  name: 'TWImportView',
})
export default class TWImportView extends Vue {
  get activePage() {
    return this.$store.state.tradeWaltz.navigation.currentPage
  }

  set activePage(val: ETradeWaltzPage) {
    this.$store.commit(SET_DISPLAY_PAGE, {
      navigation: {
        page: val,
      },
    })
  }

  handleClick(tab: unknown, event: Event) {
    // console.log(tab, event)
  }
}
