
import { parseObjectToTree, DUMMY_GOODS_STRUCTURE, sortTrackingPoints } from '@/utils/utils'
import { getBarcodeImportStaticHeader, getCustomFieldHeadersOptions } from '@/utils/helpers'
import { FIELD_TYPE } from 'smartbarcode-web-core/src/utils/constants'
import { objectToArray } from 'smartbarcode-web-core/src/utils/helpers'
import {
  IBarcodeDefinitionType,
  IBarcodeImportSearch,
  IFieldOption,
  IProject,
  ITrackpoint,
  IBindingSetting,
  IRoutePath,
} from 'smartbarcode-web-core/src/utils/types'
import { Vue } from 'vue-class-component'
import { PropSync, InjectReactive } from 'vue-property-decorator'
import cloneDeep from 'lodash/cloneDeep'

export default class TWBindingDialogMixins extends Vue {
  @PropSync('isShowDialog', { type: Boolean }) _isShowDialog!: boolean
  @InjectReactive() dataSearch!: IBarcodeImportSearch
  @InjectReactive() barcodeType!: string

  excludedFieldType = [
    FIELD_TYPE.FILE,
    FIELD_TYPE.ESIGN,
    FIELD_TYPE.SINGLE_SELECT,
    FIELD_TYPE.MULTI_SELECT,
    FIELD_TYPE.FILES,
    FIELD_TYPE.LOCATION,
    FIELD_TYPE.GHG_EMISSION,
    FIELD_TYPE.CALCULATION,
  ]

  bindingBarcodeModel = {} as IBindingSetting

  get rules() {
    const linkageGoodsLineBinding = this.bindingBarcodeModel?.linkageGoodsLineBinding?.map(() => {
      return {
        from: [{ required: true, validator: this.requiredValidate, trigger: 'change' }],
        to: [{ required: true, validator: this.requiredValidate, trigger: 'change' }],
      }
    })
    return {
      linkageGoodsLineBinding,
      startTrackingPointKey: [{ required: true, validator: this.requiredValidate, trigger: 'change' }],
    }
  }

  get trackingPointDataShown() {
    const arrTrackPoints = objectToArray<ITrackpoint>(this.getProject.trackPoints) || []
    sortTrackingPoints(arrTrackPoints)
    this.trackingPointDatasArr = arrTrackPoints
    return cloneDeep(arrTrackPoints).filter(
      (val) => !val.value.isEnd && this.currentBarcodeType.overridableStartTrackPoints.includes(val.key)
    )
  }

  onRemoveBindingModel(idx: number) {
    this.bindingBarcodeModel.linkageGoodsLineBinding.splice(idx, 1)
  }

  onAddBindingModel() {
    this.bindingBarcodeModel.linkageGoodsLineBinding.push({
      from: '',
      to: '',
    })
  }

  get getTreeData() {
    return parseObjectToTree(DUMMY_GOODS_STRUCTURE)
  }

  get getProject(): IProject {
    return this.$store.state.barcodeImport?.projectList
  }

  get barcodeTypes(): Record<string, IBarcodeDefinitionType> {
    return this.getProject?.barcodeTypes || {}
  }

  get barcodeName(): string {
    return this.currentBarcodeType?.name || ''
  }

  get projectName(): string {
    return this.getProject?.name || ''
  }

  get getBarcodeTypeName(): string {
    return this.barcodeTypes?.[this.barcodeType]?.name || Object.values(this.barcodeTypes)[0].name
  }

  get currentBarcodeType(): IBarcodeDefinitionType {
    return this.barcodeTypes?.[this.barcodeType] || Object.values(this.barcodeTypes)[0]
  }

  get customFieldsOptions(): IFieldOption[] {
    const options = getBarcodeImportStaticHeader()
    const item = this.currentBarcodeType
    const customFields = Object.entries(item.activationFields.customFields).map(([k, v]) => ({
      ...v,
      customFieldKey: k,
    }))
    const customFieldOptions = getCustomFieldHeadersOptions({
      fields: customFields || [],
      excludeFieldType: this.excludedFieldType,
    })
    options.push(...customFieldOptions)

    return options
  }

  isDisableKey(optionKey: string, bindingKey: keyof IRoutePath): boolean {
    return this.bindingBarcodeModel.linkageGoodsLineBinding.some((option) => option?.[bindingKey] === optionKey)
  }
}
