<template>
  <el-input-number
    :min="min"
    :max="max"
    :id="id"
    :name="name"
    :controls="controls"
    :precision="precision"
    @keydown="handleKeydown($event, $event.target.value)"
    @click.stop
    v-model="_modelValue"
    :class="`${classes} w-full`"
    :size="size"
    @blur="updateTrackPoint"
    :placeholder="placeholder"
  />
</template>

<script lang="ts">
import { Options } from 'vue-class-component'
import { Mixins, Prop, PropSync } from 'vue-property-decorator'
import InputOnlyNumberMixin from '@/components/mixins/InputOnlyNumberMixin.vue'

@Options({
  emits: ['update:trackpoint'],
  name: 'InputOnlyNumber',
})
export default class InputOnlyNumber extends Mixins(InputOnlyNumberMixin) {
  @PropSync('modelValue', { type: Number }) _modelValue?: number
  @Prop({ type: Boolean }) readonly controls = false
  @Prop({ type: Number }) readonly min?: number
  @Prop({ type: Number }) readonly max?: number
  @Prop({ type: Number }) readonly precision?: number

  updateTrackPoint() {
    this.$emit('update:trackpoint')
  }
}
</script>
