
import { activateTWProess } from '@/utils/api'
import errorHandler from '@/utils/errorHandler'
import { openMessage } from '@/utils/utils'
import { EEntityStatus } from 'smartbarcode-web-core/src/utils/enums'
import { isEmpty } from 'smartbarcode-web-core/src/utils/typeChecker'
import {
  IProject,
  IBarcodeDefinitionType,
  IBarcodeImportSearch,
  ITWProcess,
  IBindingSetting,
  ITWSendActivateProcess,
} from 'smartbarcode-web-core/src/utils/types'
import { EBindingSetting } from 'smartbarcode-web-core/src/utils/types/tradeWaltzTypes/index'
import { Vue } from 'vue-class-component'
import { InjectReactive } from 'vue-property-decorator'

export default class TWPOMainComponent extends Vue {
  @InjectReactive() dataSearch!: IBarcodeImportSearch
  @InjectReactive() barcodeType!: string
  isShowBindSettingdialog = false
  isLoading = false

  get isBindingSetingEmpty() {
    if (this.bindingSetting === null) return true
    return isEmpty(this.bindingSetting.startTrackingPointKey) || isEmpty(this.bindingSetting.linkageGoodsLineBinding)
  }

  get getProject(): IProject {
    return this.$store.state.barcodeImport?.projectList
  }

  get barcodeTypes(): Record<string, IBarcodeDefinitionType> {
    return this.getProject?.barcodeTypes || {}
  }

  get projectName(): string {
    return this.getProject?.name || ''
  }

  get getBarcodeTypeName(): string {
    return this.barcodeTypes?.[this.barcodeType]?.name || Object.values(this.barcodeTypes)[0].name
  }

  async onProcessActivateClick(item: ITWProcess, idx: number) {
    const bindingSetting = this.bindingSetting as IBindingSetting
    const bindingObject = {
      linkageGoodsLineBinding: [],
      linkageGoodsLinePackingBinding: [],
      linkageGoodsLinePriceBinding: [],
      bindings: [],
    } as ITWSendActivateProcess
    bindingSetting.linkageGoodsLineBinding.forEach((element) => {
      const value = element.from.split('-')
      switch (value[0]) {
        case EBindingSetting.LINKAGE_GOODS_LINE_BINDING:
          bindingObject.linkageGoodsLineBinding.push({
            from: value[1],
            to: element.to,
          })
          break
        case EBindingSetting.LINKAGE_GOODS_LINE_PACKING_BINDING:
          bindingObject.linkageGoodsLinePackingBinding.push({
            from: value[1],
            to: element.to,
          })
          break
        case EBindingSetting.LINKAGE_GOODS_LINE_PRICE_BINDING:
          bindingObject.linkageGoodsLinePriceBinding.push({
            from: value[1],
            to: element.to,
          })
          break
        default:
          bindingObject.bindings.push({
            from: value[0],
            to: element.to,
          })
          break
      }
    })

    try {
      this.isLoading = true
      const dataToSend = {
        entityId: item.entityId,
        processId: item.processId,
        processSeq: item.processSeq,
        barcodeType: this.barcodeType,
        startTrackingPointKey: this.bindingSetting.startTrackingPointKey,
        projectCode: this.dataSearch.projectCode,
        projectVersion: this.dataSearch.version,
        isDryRun: true,
        ...bindingObject,
      }

      const data = await activateTWProess(dataToSend)
      const { barcodeCreationResults, successCount, errorMessage } = data
      if (successCount === 0 && errorMessage) {
        openMessage(errorMessage, 'error')
        return
      }
      if (barcodeCreationResults.length === 0) {
        openMessage(this.$t('tradewaltz.no_goods_error'), 'error')
        return
      }

      await this.$confirm(
        this.$t('tradewaltz.confirm_publish_process', {
          successCount: successCount,
          barcodeName: this.getBarcodeTypeName,
        }),
        this.$t('info'),
        {
          confirmButtonText: this.$t('confirm'),
          confirmButtonClass: 'danger',
          cancelButtonText: this.$t('cancel'),
        }
      )
        .then(async () => {
          dataToSend.isDryRun = false
          await activateTWProess(dataToSend)
        })
        // eslint-disable-next-line @typescript-eslint/no-empty-function
        .catch(() => {})
    } catch (error) {
      errorHandler(error)
    } finally {
      this.isLoading = false
    }
  }

  isDisableActivateButton(item: ITWProcess) {
    return !this.isProgressCompleted(item.entityProgressStatusCd) || this.isBindingSetingEmpty
  }

  isProgressCompleted(value: EEntityStatus) {
    switch (value) {
      case EEntityStatus.CLOSED:
        return true
      default:
        return false
    }
  }

  getProgressLabel(value: EEntityStatus) {
    switch (value) {
      case EEntityStatus.NOT_STARTED:
        return this.$t('tradewaltz.status.not_started')
      case EEntityStatus.IN_PROGRESS:
        return this.$t('tradewaltz.status.processing')
      case EEntityStatus.CLOSED:
        return this.$t('tradewaltz.status.closed')
    }
  }

  onShowBindingSettingDialog() {
    this.isShowBindSettingdialog = true
  }
}
