
import cloneDeep from 'lodash/cloneDeep'
import { mixins, Options } from 'vue-class-component'
import TWPOBindingDialogComponent from './TWPOBindingDialogComponent.vue'
import MainComponentMixin from '../MainComponentMixins.vue'
import { IBindingSetting, ITWProcess } from 'smartbarcode-web-core/src/utils/types'
@Options({
  components: {
    TWPOBindingDialogComponent,
  },
  name: 'TWPOMainComponent',
  inheritAttrs: false,
})
export default class TWPOMainComponent extends mixins(MainComponentMixin) {
  get displayItems(): ITWProcess[] {
    return this.$store.getters.getPOProcessList || []
  }

  get bindingSetting(): IBindingSetting {
    return cloneDeep(this.$store.state.tradeWaltz.bindingSettings.reqPOBindingSettings)
  }
}
