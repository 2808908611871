
import cloneDeep from 'lodash/cloneDeep'
import { mixins, Options } from 'vue-class-component'
import TWStoreBLBindingDialogComponent from './TWStoreBLBindingDialogComponent.vue'
import MainComponentMixin from '../MainComponentMixins.vue'
import { ITWProcess, IBindingSetting } from 'smartbarcode-web-core/src/utils/types'
@Options({
  components: {
    TWStoreBLBindingDialogComponent,
  },
  name: 'TradewaltzPOMainComponent',
  inheritAttrs: false,
})
export default class TradewaltzPOMainComponent extends mixins(MainComponentMixin) {
  get displayItems(): ITWProcess[] {
    return this.$store.getters.getStoreBLProcessList || []
  }

  get bindingSetting(): IBindingSetting {
    return cloneDeep(this.$store.state.tradeWaltz?.bindingSettings?.storeBLBindingSettings)
  }
}
