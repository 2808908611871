<template>
  <el-tree
    @check="(node) => onNodeClick(node)"
    :accordion="true"
    :data="treeData"
    show-checkbox
    :node-key="nodeKey"
    :default-checked-keys="getBindingChecked()"
    :default-expanded-keys="getBindingChecked()"
    :check-strictly="true"
    :ref="'treeRef_' + key"
  ></el-tree>
</template>

<script lang="ts">
import SelectAutocomplete from '@/components/common/SelectAutocomplete.vue'
import { Options, Vue } from 'vue-class-component'
import { Prop, PropSync } from 'vue-property-decorator'
import cloneDeep from 'lodash/cloneDeep'
import { reverseLoopKeyPath } from '@/utils/utils'
import { isEmpty } from 'smartbarcode-web-core/src/utils/typeChecker'
import { TreeData } from 'element-plus/lib/el-tree/src/tree.type'

interface TreeNode {
  id: number
  label: string
  keyPath: string
  children?: TreeNode[]
}

@Options({
  components: {
    SelectAutocomplete,
  },
  name: 'TreeSelectComponent',
  emits: ['update:dataModel'],
  inheritAttrs: false,
})
export default class TreeSelectComponent extends Vue {
  @PropSync('dataModel', { type: String }) _dataModel!: string
  @Prop({ default: [] }) treeDataArr: TreeData = []
  @Prop({ default: 0 }) key!: number
  @Prop({ default: '' }) nodeKey!: string

  get treeData() {
    return this.treeDataArr
  }

  onNodeClick(data: TreeNode) {
    const treeInstance = this.$refs[`treeRef_${this.key}`] || undefined
    if (treeInstance) {
      // Uncheck if parent is checked
      if (!isEmpty(data.children)) {
        treeInstance.setChecked(data.keyPath, false)
        return
      }
      const previousKeyPath = cloneDeep(this._dataModel)
      // Uncheck other node
      const nodeData = treeInstance.getNode(previousKeyPath)
      if (nodeData) {
        reverseLoopKeyPath(previousKeyPath, (currentPath, iteration) => {
          treeInstance.setChecked(currentPath, false)
        })
      }
      // Checked all parent path
      reverseLoopKeyPath(data.keyPath, (currentPath, iteration) => {
        treeInstance.setChecked(currentPath, true)
      })
      this._dataModel = data.keyPath
    }
  }

  getBindingChecked() {
    const parentKey: string[] = []
    reverseLoopKeyPath(this._dataModel, (currentPath, iteration) => {
      parentKey.push(currentPath)
    })
    return parentKey
  }
}
</script>
