
import BarcodeIcon from '@/components/BarcodeIcon.vue'
import BarcodePDF from '@/components/barcodeImport/BarcodePDF.vue'
import InputOnlyNumber from '@/components/common/InputOnlyNumber.vue'
import ItemNotFound from '@/components/common/ItemNotFound.vue'
import PageHeader from '@/components/common/PageHeader.vue'
import DataTableMixin from '@/components/mixins/DataTableMixin.vue'
import IconBulkBarcode from '@/components/svg/IconBulkBarcode.vue'
import IconDownload from '@/components/svg/IconDownload.vue'
import IconPDF from '@/components/svg/IconPDF.vue'
import IconUpload from '@/components/svg/IconUpload.vue'
import {
  LOAD_BARCODE_IMPORT_LIST,
  LOAD_BARCODE_IMPORT_PROJECT,
  LOAD_CLIENT_LIST,
  LOAD_PROJECTS_LIST,
  SAVE_TW_BINDING_SETTING,
} from '@/store/actions'
import { bulkPublish, getBarcodeCSVExample, getBarcodeImportDetail, uploadBarcodeImportCSV } from '@/utils/api'
import { BARCODE_IMPORT_LAYOUT_PDF_EXPORT, OPERATION_TYPE } from '@/utils/constants'
import errorHandler from '@/utils/errorHandler'
import { saveCSVText } from '@/utils/exportPdf'
import { getTradeWaltzStoreBindingSetting } from '@/utils/helpers'
import { openMessage } from '@/utils/utils'
import TradeWaltzViewDialog from '@/views/tradeWaltz/TradeWaltzViewDialog.vue'
import { EBarcodePublishStatus } from 'smartbarcode-web-core/src/utils/enums/index'
import {
  IBarcodeCreationResult,
  IBarcodeDefinitionType,
  IBarcodeImportDetail,
  IBarcodeImportList,
  IBarcodeImportRow,
  IBarcodeImportSearch,
  IClientList,
  IProject,
} from 'smartbarcode-web-core/src/utils/types/index'
import { Options } from 'vue-class-component'
import { Mixins, ProvideReactive, Watch } from 'vue-property-decorator'

export interface IClient {
  id: string
  name: string
  email: string
}
@Options({
  components: {
    PageHeader,
    ItemNotFound,
    BarcodeIcon,
    IconBulkBarcode,
    IconUpload,
    IconDownload,
    IconPDF,
    BarcodePDF,
    InputOnlyNumber,
    TradeWaltzViewDialog,
  },
  name: 'BarcodeImport',
})
export default class BarcodeImport extends Mixins(DataTableMixin) {
  @ProvideReactive() projectVersion = 0
  @ProvideReactive() barcodeType = ''
  @ProvideReactive() dataSearch = {} as IBarcodeImportSearch
  operationTypes = ''
  barcodeAmount = 1
  isShowDialog = false
  defaultSortKey = 'status'
  canNotClickColumn = 'pdfColumn'
  barcodeImportId = ''
  barcodeExports = [] as IBarcodeCreationResult[]
  barcodeImportOperationType = ''
  loading = false
  selectedBarcodeType = ''
  isShowTradeWaltzDialog = false
  assignedClientIds = [] as string[]
  checkedClientIds = []
  isShowClients = false

  tableCellClassName(object: IBarcodeImportRow) {
    if (object.row.status === EBarcodePublishStatus.COMPLETED) {
      if (object.row.errorCount > 0) {
        return 'warning-row custom-table__cell cursor-pointer'
      }

      if (object.columnIndex === 0) {
        return 'completed-row'
      }
      return 'custom-table__cell cursor-pointer'
    } else if (object.row.status === EBarcodePublishStatus.ERROR) {
      return 'error-row custom-table__cell cursor-pointer'
    } else {
      return 'custom-table__cell cursor-pointer'
    }
  }

  translatedProgress(val: string, row: IBarcodeImportList) {
    if (!val) return ''
    return `${this.$t(`barcode_status.${val}`)}${this.isWarningRow(row) ? '*' : ''}`
  }

  isWarningRow(val: IBarcodeImportList) {
    return val?.status === EBarcodePublishStatus.COMPLETED && val?.errorCount > 0
  }

  get getItems(): IBarcodeImportList[] {
    return this.$store.state.barcodeImport?.barcodeImports
  }

  get getTotalBarcode(): number {
    return this.$store.state.barcodeImport?.totalBarcodes
  }

  get getProject(): IProject {
    return this.$store.state.barcodeImport?.projectList
  }

  get getBarcodes(): Record<string, IBarcodeDefinitionType> {
    return this.getProject?.barcodeTypes || {}
  }

  get clients(): IClient[] {
    return ([...this.$store.state.client.clients]
      .filter((val: IClientList) => !!val.clientUser)
      .map((c: IClientList) => ({
        id: c.clientUser.id,
        name: c.client.name,
        email: c.clientUser.email,
      })) ?? []) as IClient[]
  }

  get isSetDisplayedBarcodeByClient() {
    return this.$store.state.profile?.organization?.setDisplayedBarcodeByClient || false
  }

  get completedStatus(): string {
    return EBarcodePublishStatus.COMPLETED
  }

  get barcodeImportLayouts() {
    const result = {} as Record<number, string>
    for (const key in BARCODE_IMPORT_LAYOUT_PDF_EXPORT) {
      result[BARCODE_IMPORT_LAYOUT_PDF_EXPORT[key]] = this.$t(`barcodeImport.${key}`)
    }
    return result
  }

  formatBarcodeType(barcodeTypeDefinition: IBarcodeDefinitionType): IBarcodeDefinitionType {
    return barcodeTypeDefinition
  }

  exportPDFDone() {
    this.barcodeExports = []
  }

  async showExportDialog(barcodeImportId: string, barcodeType: string) {
    this.barcodeImportId = barcodeImportId
    this.barcodeExports = []

    try {
      this.loading = true
      const data = (await getBarcodeImportDetail(barcodeImportId)) || ({} as IBarcodeImportDetail)
      if (data && data.results.length > 0) {
        const existBarcodeImport = (this.getItems ?? []).find(
          (item) => item.id === barcodeImportId
        ) as IBarcodeImportList
        this.barcodeImportOperationType = existBarcodeImport.operationType
        this.projectVersion = Number(existBarcodeImport.projectVersion || 0)
        this.barcodeExports = data.results
        this.selectedBarcodeType = barcodeType || ''
        this.$refs.barcodePDFComponent.showComfirmPopup()
        window.scrollTo(0, 0)
      } else {
        openMessage(this.$t('errors.2303'), 'error')
      }
    } catch (error) {
      openMessage(error as string, 'error')
    } finally {
      this.loading = false
    }
  }

  @Watch('barcodeExports')
  hideExportPDFDialog() {
    if (this.barcodeExports.length === 0) {
      this.barcodeImportId = ''
    }
  }

  async fetchBarcodesList(data: IBarcodeImportSearch, isLoad = true) {
    const newData = {
      projectCode: data.projectCode,
      version: data.version,
      skip: (this.currentPageNum - 1) * this.itemsPerPage,
      count: this.itemsPerPage,
      t: this.$t,
      te: this.$te,
    }
    if (isLoad) this.redirectIndex()

    this.loading = true
    await this.$store.dispatch(LOAD_BARCODE_IMPORT_LIST, newData).finally(() => (this.loading = false))
  }

  get getProjectsList(): IProject[] {
    return this.$store.state.project?.originalProjects.filter((item: IProject) => {
      return !(item.isDraft && item.isDraft === true)
    })
  }

  get maxProjectVersion() {
    return this.getProjectsList.find((val) => val?.code === this.dataSearch?.projectCode)?.version ?? 1
  }

  get projectName() {
    return this.getProjectsList.find((val) => val?.code === this.dataSearch?.projectCode)?.name ?? ''
  }

  get isTradeWaltzEnable() {
    return this.$store.getters.isTradeWaltzPermission
  }

  async onSelectProject() {
    this.barcodeType = ''
    this.currentPageNum = 1
    this.getLastestVersion(this.dataSearch.projectCode)
    await this.fetchBarcodesList(this.dataSearch)
  }

  async onVersionChange() {
    const newData = {
      projectCode: this.dataSearch.projectCode,
      version: this.dataSearch.version,
      t: this.$t,
      te: this.$te,
    }
    this.redirectIndex()
    await this.$store.dispatch(LOAD_BARCODE_IMPORT_PROJECT, newData).finally(() => (this.loading = false))
  }

  onEditBarcode(barcode: IBarcodeImportList, column: Record<string, unknown>) {
    if (
      column.property === this.canNotClickColumn ||
      barcode.operationType === OPERATION_TYPE.BULK_CREATE ||
      barcode.status === EBarcodePublishStatus.REQUESTED ||
      barcode.status === EBarcodePublishStatus.PROGRESS
    ) {
      return
    }

    this.$router.push({
      name: 'barcodeImportDetail',
      params: { id: barcode.id, projectCode: this.dataSearch?.projectCode },
    })
  }

  async refreshList() {
    await this.fetchBarcodesList(this.dataSearch, false)
  }

  async created() {
    this.routeName = 'barcodePublish'
    await this.fetchClients()
    this.getQueryData()

    this.isSort = true

    if (!this.$store.state.project?.isLoaded) {
      this.loading = true
      await this.$store.dispatch(LOAD_PROJECTS_LIST).finally(() => (this.loading = false))
    }

    if (!this.dataSearch.projectCode) {
      this.dataSearch.projectCode = this.getProjectsList?.[0].code ?? ''
    }

    if (!this.dataSearch.version) {
      this.getLastestVersion(this.dataSearch.projectCode)
    }
    await this.fetchBarcodesList(this.dataSearch)
  }

  getLastestVersion(projectCode: string) {
    const chooseProject = this.getProjectsList.find((item) => item.code === projectCode)
    this.dataSearch.version = chooseProject?.version ?? 1
  }

  showBulkPublishDialog() {
    this.isShowDialog = true
  }

  async fetchClients() {
    if (!this.isSetDisplayedBarcodeByClient) return
    if (this.clients.length > 0) return
    try {
      this.loading = true
      await this.$store.dispatch(LOAD_CLIENT_LIST)
    } catch (error) {
      errorHandler(error)
    } finally {
      this.loading = false
    }
  }

  async onBulkPublish() {
    const projectId = this.getProject?.id || ''
    try {
      this.loading = true
      await bulkPublish(projectId, this.barcodeAmount, this.assignedClientIds)
      openMessage(this.$t('publish_successful'), 'success')
      await this.fetchBarcodesList(this.dataSearch, false)

      this.isShowDialog = false
    } catch (error) {
      openMessage(error as string, 'error')
    } finally {
      this.loading = false
    }
  }

  async onCsvFilePicked(firstCsvFile: Blob) {
    try {
      this.loading = true
      const projectId = this.getProject?.id || ''
      const barcodeType = this.barcodeType || ''
      const formData = new FormData()
      formData.append('file', firstCsvFile)
      formData.append('projectId', projectId)
      formData.append('barcodeType', barcodeType)
      formData.append('operationType', this.operationTypes)
      this.assignedClientIds.forEach((element) => {
        formData.append('clientIds[]', element)
      })
      await uploadBarcodeImportCSV(formData, () => false)
      openMessage(this.$t('publish_successful'), 'success')
      await this.fetchBarcodesList(this.dataSearch)
    } catch (error) {
      if (
        !!error &&
        typeof error === 'object' &&
        'File' in error &&
        error.File !== undefined &&
        Array.isArray(error.File)
      ) {
        const errCode = error.File?.[0] ?? ''
        if (errCode.includes('1015')) {
          const limit = errCode.substring(errCode.indexOf('('), errCode.indexOf(')'))
          openMessage(this.$t('errors.1015', { val1: limit }), 'error')
        }
      } else {
        openMessage(error as string, 'error')
      }
    } finally {
      this.loading = false
    }

    this.$refs.bulkCsvInput.value = null
  }

  uploadNewCSV(operationType: string) {
    const projectId = this.getProject?.id || ''
    const barcodeType = this.barcodeType || ''
    if (!projectId || !barcodeType) {
      openMessage(this.$t('barcodeImport.please_select_type'), 'error')
    } else {
      this.operationTypes = operationType || ''
      this.$refs.bulkCsvInput.click()
    }
  }

  openTradeWaltzDialog() {
    this.isShowTradeWaltzDialog = true

    const TWBindingSetting = getTradeWaltzStoreBindingSetting()
    this.$store.commit(SAVE_TW_BINDING_SETTING, { ...TWBindingSetting })
  }

  onTradeWaltzDialogClosed() {
    this.fetchBarcodesList(this.dataSearch)
  }

  setAssignedClientIds() {
    this.assignedClientIds = this.checkedClientIds
    this.isShowClients = false
  }

  async downloadCSV() {
    this.loading = true
    const projectId = this.getProject?.id || ''
    const barcodeType = this.barcodeType || ''
    try {
      if (!projectId || !barcodeType) {
        openMessage(this.$t('barcodeImport.please_select_type'), 'error')
      } else {
        const data = await getBarcodeCSVExample(projectId, barcodeType)
        saveCSVText(data, barcodeType)
      }
    } catch (error) {
      openMessage(error as string, 'error')
    } finally {
      this.loading = false
    }
    return true
  }

  async currentPageChange(pageNum: number) {
    this.currentPageNum = pageNum
    await this.fetchBarcodesList(this.dataSearch)
  }
}
