<template>
  <div class="h-full w-full">
    <TradeWaltzPOMainComponent />
  </div>
</template>

<script lang="ts">
import { Vue, Options } from 'vue-class-component'
import TradeWaltzPOMainComponent from '../components/PO/TWPOMainComponent.vue'
import { ETradeWaltzSubPage } from '../enums'
@Options({
  components: { TradeWaltzPOMainComponent },
  name: 'PurchaseOrderView',
})
export default class PurchaseOrderView extends Vue {
  get arrowLeftIcon() {
    return 'el-icon-arrow-left'
  }

  get isNotMainPage() {
    return this.currentPage !== ETradeWaltzSubPage.MAIN
  }

  get currentPage(): ETradeWaltzSubPage {
    return this.$store.state.tradeWaltz.navigation.subPage
  }
}
</script>
<style scoped>
@import '~@/assets/css/mixins.scss';
</style>
