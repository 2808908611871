
import SelectAutocomplete from '@/components/common/SelectAutocomplete.vue'
import { Options, Vue } from 'vue-class-component'
import TreeSelectComponent from '@/components/baseComponent/TreeSelectComponent.vue'
import TWBindingDialogComponent from '../TWBindingDialogComponent.vue'
import { PropSync } from 'vue-property-decorator'
import { getTWBLLinkageGoodsLineBindingOption } from '@/utils/helpers'
@Options({
  components: {
    SelectAutocomplete,
    TreeSelectComponent,
    TWBindingDialogComponent,
  },
  name: 'TradeWaltzPOBindingDialogComponent',
  emits: ['update:isShowDialog'],
  inheritAttrs: false,
})
export default class TradeWaltzPOBindingDialogComponent extends Vue {
  @PropSync('isShowDialog', { type: Boolean }) _isShowDialog!: boolean
  tradeWaltzStoreKey = 'storeBLBindingSettings'

  get twBLBindingOptions() {
    return getTWBLLinkageGoodsLineBindingOption()
  }
}
