<template>
  <div>
    <el-dialog
      custom-class="el-dialog--custom"
      :title="title"
      :lock-scroll="true"
      v-model="_isShowDialog"
      width="100%"
      top="0"
      :close-on-click-modal="false"
      :destroy-on-close="true"
    >
      <el-form
        class="frm-custom-validator"
        :label-position="'right'"
        label-width="160px"
        :rules="rules"
        :model="bindingBarcodeModel"
        :ref="tradeWaltzStoreKey"
      >
        <div class="flex flex-row justify-between w-2/3 leading-3">
          <div>
            {{ `${$t('projects.projectName')}: ${projectName}` }}
          </div>
          <div>
            {{ `${$t('projects.barcodeType')}: ${barcodeName}` }}
          </div>
          <div>{{ `${$t('projects.version')}: ${dataSearch.version}` }}</div>
        </div>
        <div class="flex flex-col justify-between my-2">
          <div>
            <label>{{ $t('barcode_type.start_tracking_point') }}</label>
          </div>
          <el-form-item :prop="`startTrackingPointKey`" :label-width="'0px'">
            <el-select
              class="my-4"
              :placeholder="$t('barcode_type.select')"
              v-model="bindingBarcodeModel.startTrackingPointKey"
            >
              <el-option
                v-for="tp in trackingPointDataShown"
                :key="tp.key"
                :label="`${tp.value.name} (${tp.key})`"
                :value="tp.key"
              >
                {{ tp.value.name }} {{ `(${tp.key})` }}
              </el-option>
            </el-select>
          </el-form-item>
        </div>
        <div
          v-for="(bindingModel, idx) in bindingBarcodeModel.linkageGoodsLineBinding"
          :key="idx"
          class="grid grid-cols-3 my-4 border border-gray-400 border-solid relative p-5"
        >
          <div class="absolute control-button">
            <el-button
              v-if="bindingBarcodeModel.linkageGoodsLineBinding.length > 1"
              type="default"
              icon="el-icon-minus"
              class="bg-gray-400 text-white"
              size="small"
              circle
              @click="onRemoveBindingModel(idx)"
            >
            </el-button>
            <el-button
              v-if="idx === bindingBarcodeModel.linkageGoodsLineBinding.length - 1"
              type="primary"
              icon="el-icon-plus"
              size="small"
              circle
              @click="onAddBindingModel()"
            >
            </el-button>
          </div>
          <div>
            <el-form-item :prop="`linkageGoodsLineBinding.${idx}.from`" :label-width="'0px'">
              <!-- <TreeSelectComponent
                v-model:dataModel="bindingBarcodeModel.linkageGoodsLineBinding[idx].from"
                :treeDataArr="getTreeData"
                :key="idx"
                :nodeKey="'keyPath'"
              /> -->
              <!-- <SelectAutocomplete
                :placeholder="'Looking from barcode type'"
                v-model="bindingModel.from"
                :options="TWSelectionOptions"
              /> -->
              <el-select v-model="bindingModel.from" size="medium" class="w-full">
                <el-option
                  v-for="option in TWSelectionOptions"
                  :disabled="isDisableKey(option.value, 'from')"
                  :key="option"
                  :label="option.label"
                  :value="option.value"
                >
                  {{ option.label }}
                </el-option>
              </el-select>
            </el-form-item>
          </div>
          <div class="text-center self-center">
            →
          </div>
          <div class="self-center">
            <el-form-item :prop="`linkageGoodsLineBinding.${idx}.to`" :label-width="'0px'">
              <el-select v-model="bindingModel.to" size="medium" class="w-full">
                <el-option
                  v-for="option in customFieldsOptions"
                  :disabled="isDisableKey(option.value, 'to')"
                  :key="option"
                  :label="option.label"
                  :value="option.value"
                >
                  {{ option.label }}
                </el-option>
              </el-select>
              <!-- <SelectAutocomplete
                :placeholder="'Looking from barcode type'"
                v-model="bindingModel.to"
                :options="customFieldsOptions"
              /> -->
            </el-form-item>
          </div>
        </div>
      </el-form>
      <template #footer>
        <div class="flex">
          <div class="flex-1">
            <el-button type="default" class="btn-default-cancel" @click="_isShowDialog = false">
              {{ $t('cancel') }}
            </el-button>
          </div>
          <div class="flex-1">
            <el-button type="primary" @click="onSaveSetting()">
              {{ $t('save') }}
            </el-button>
          </div>
        </div>
      </template>
    </el-dialog>
  </div>
  <div />
</template>
<script lang="ts">
import SelectAutocomplete from '@/components/common/SelectAutocomplete.vue'
import { mixins, Options } from 'vue-class-component'
import TreeSelectComponent from '@/components/baseComponent/TreeSelectComponent.vue'
import { Prop } from 'vue-property-decorator'
import { SAVE_TW_BINDING_SETTING } from '@/store/actions'
import ValidateForm from '@/components/mixins/ValidateForm.vue'
import TWBindingDialogMixins from './TWBindingDialogMixins.vue'
import cloneDeep from 'lodash/cloneDeep'
import { IBindingSetting, IFieldOption } from 'smartbarcode-web-core/src/utils/types'
import { setTradeWaltzStoreBindingSetting } from '@/utils/helpers'

@Options({
  components: {
    SelectAutocomplete,
    TreeSelectComponent,
  },
  name: 'TWBindingDialogComponent',
  emits: ['update:isShowDialog'],
  inheritAttrs: false,
})
export default class TWBindingDialogComponent extends mixins(ValidateForm, TWBindingDialogMixins) {
  @Prop({ default: '' }) tradeWaltzStoreKey!: string
  @Prop({ default: [] }) TWSelectionOptions!: IFieldOption[]
  @Prop({ default: '' }) title!: string

  mounted() {
    const bindingSetting: IBindingSetting = cloneDeep(
      this.$store.state.tradeWaltz?.bindingSettings?.[this.tradeWaltzStoreKey]
    )
    this.bindingBarcodeModel = bindingSetting || {
      startTrackingPointKey: '',
      linkageGoodsLineBinding: [{ from: '', to: '' }],
    }
  }

  onSaveSetting() {
    // eslint-disable-next-line no-unused-expressions
    this.$refs?.[this.tradeWaltzStoreKey].validate(async (valid: string) => {
      if (valid) {
        this.$store.commit(SAVE_TW_BINDING_SETTING, { [this.tradeWaltzStoreKey]: this.bindingBarcodeModel })
        setTradeWaltzStoreBindingSetting(this.$store.state.tradeWaltz?.bindingSettings)
        this._isShowDialog = false
      } else {
        return false
      }
    })
  }
}
</script>
<style lang="scss" scoped>
::v-deep {
  .el-dialog {
    max-width: 50% !important;
  }
}

.control-button {
  left: 50%;
  bottom: 0%;
  transform: translate(-50%, 50%);
}
</style>
