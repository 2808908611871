<template>
  <SvgBase iconName="IconBulkBarcode" :height="22" :width="22">
    <g clip-path="url(#clip0)">
      <path d="M10.6667 5H8V7.66667H10.6667V5Z" :fill="color" />
      <path d="M13.3333 7.66696H10.6667V10.3336H13.3333V7.66696Z" :fill="color" />
      <path d="M16 5H13.3333V7.66667H16V5Z" :fill="color" />
      <path d="M10.6667 10.333H8V12.9997H10.6667V10.333Z" :fill="color" />
      <path d="M16 10.333H13.3333V12.9997H16V10.333Z" :fill="color" />
    </g>
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M4.25 0.25H14.0342L19.75 5.30733V17.75H4.25V0.25ZM5.75 1.75V16.25H18.25V6.39516H13V1.75H5.75ZM14.5 2.66502L17.0205 4.89516H14.5V2.66502Z"
      :fill="color"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M0.25 4.25H4.65217V5.75H1.75V20.25H14.25V17.1379H15.75V21.75H0.25V4.25Z"
      :fill="color"
    />
  </SvgBase>
</template>

<script lang="ts">
import { Vue, Options } from 'vue-class-component'
import SvgBase from './SvgBase.vue'

@Options({
  components: {
    SvgBase,
  },
  props: {
    color: {
      type: String,
      default: 'currentColor',
    },
  },
})
export default class IconBulkBarcode extends Vue {}
</script>
