<template>
  <div>
    <TWBindingDialogComponent
      v-if="_isShowDialog"
      :title="`${$t('tradewaltz.send_po')}`"
      v-model:isShowDialog="_isShowDialog"
      :tradeWaltzStoreKey="tradeWaltzStoreKey"
      :TWSelectionOptions="twBookingBindingOptions"
    />
  </div>
  <div />
</template>
<script lang="ts">
import SelectAutocomplete from '@/components/common/SelectAutocomplete.vue'
import { Options, Vue } from 'vue-class-component'
import TreeSelectComponent from '@/components/baseComponent/TreeSelectComponent.vue'
import TWBindingDialogComponent from '../TWBindingDialogComponent.vue'
import { PropSync } from 'vue-property-decorator'
import {
  getTWPOBindingOption,
  getTWPOLinkageGoodsLineBindingOption,
  getTWPOLinkageGoodsLinePackingBindingOption,
  getTWPOLinkageGoodsLinePriceOption,
} from '@/utils/helpers'
@Options({
  components: {
    SelectAutocomplete,
    TreeSelectComponent,
    TWBindingDialogComponent,
  },
  name: 'TradeWaltzPOBindingDialogComponent',
  emits: ['update:isShowDialog'],
  inheritAttrs: false,
})
export default class TradeWaltzPOBindingDialogComponent extends Vue {
  @PropSync('isShowDialog', { type: Boolean }) _isShowDialog!: boolean
  tradeWaltzStoreKey = 'reqPOBindingSettings'

  get twBookingBindingOptions() {
    const bindingOptions = getTWPOBindingOption()
    const getLinkageGoodLineBindingOptions = getTWPOLinkageGoodsLineBindingOption()
    const getLinkageGoodLinePackingBindingOptions = getTWPOLinkageGoodsLinePackingBindingOption()
    const getLinkageGoodLinePrice = getTWPOLinkageGoodsLinePriceOption()
    return [
      ...bindingOptions,
      ...getLinkageGoodLineBindingOptions,
      ...getLinkageGoodLinePackingBindingOptions,
      ...getLinkageGoodLinePrice,
    ]
  }
}
</script>
<style lang="scss" scoped>
::v-deep {
  .el-dialog {
    max-width: 50% !important;
  }
}

.control-button {
  left: 50%;
  bottom: 0%;
  transform: translate(-50%, 50%);
}
</style>
