
import SelectAutocomplete from '@/components/common/SelectAutocomplete.vue'
import { Options, Vue } from 'vue-class-component'
import TreeSelectComponent from '@/components/baseComponent/TreeSelectComponent.vue'
import TWBindingDialogComponent from '../TWBindingDialogComponent.vue'
import { PropSync } from 'vue-property-decorator'
import {
  getTWPOBindingOption,
  getTWPOLinkageGoodsLineBindingOption,
  getTWPOLinkageGoodsLinePackingBindingOption,
  getTWPOLinkageGoodsLinePriceOption,
} from '@/utils/helpers'
@Options({
  components: {
    SelectAutocomplete,
    TreeSelectComponent,
    TWBindingDialogComponent,
  },
  name: 'TradeWaltzPOBindingDialogComponent',
  emits: ['update:isShowDialog'],
  inheritAttrs: false,
})
export default class TradeWaltzPOBindingDialogComponent extends Vue {
  @PropSync('isShowDialog', { type: Boolean }) _isShowDialog!: boolean
  tradeWaltzStoreKey = 'reqPOBindingSettings'

  get twBookingBindingOptions() {
    const bindingOptions = getTWPOBindingOption()
    const getLinkageGoodLineBindingOptions = getTWPOLinkageGoodsLineBindingOption()
    const getLinkageGoodLinePackingBindingOptions = getTWPOLinkageGoodsLinePackingBindingOption()
    const getLinkageGoodLinePrice = getTWPOLinkageGoodsLinePriceOption()
    return [
      ...bindingOptions,
      ...getLinkageGoodLineBindingOptions,
      ...getLinkageGoodLinePackingBindingOptions,
      ...getLinkageGoodLinePrice,
    ]
  }
}
