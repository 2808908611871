<template>
  <div v-loading.fullscreen:="isLoading" class="flex flex-col">
    <div class="flex justify-end">
      <el-button type="primary" icon="el-icon-plus" size="medium" @click="onShowBindingSettingDialog()">
        {{ $t('barcode_type.add_new_rule') }}
      </el-button>
    </div>
    <div class="my-4">
      <span v-if="isBindingSetingEmpty" class="text-red-500">* {{ $t('please_add_rule') }}</span>
      <div v-for="(item, idx) in displayItems" :key="idx" class="border border-gray-400 border-solid py-2 my-2">
        <div class="flex flex-row justify-between mx-4 ">
          <div class="self-center">{{ item.tradingName }}: {{ getProgressLabel(item.entityProgressStatusCd) }}</div>
          <el-button
            :disabled="isDisableActivateButton(item)"
            type="primary"
            size="medium"
            @click="onProcessActivateClick(item, idx)"
          >
            {{ $t('barcodeImport.barcodeImport') }}
          </el-button>
        </div>
      </div>
    </div>
  </div>
  <TWPOBindingDialogComponent v-if="isShowBindSettingdialog" v-model:isShowDialog="isShowBindSettingdialog" />
</template>

<script lang="ts">
import cloneDeep from 'lodash/cloneDeep'
import { mixins, Options } from 'vue-class-component'
import TWPOBindingDialogComponent from './TWPOBindingDialogComponent.vue'
import MainComponentMixin from '../MainComponentMixins.vue'
import { IBindingSetting, ITWProcess } from 'smartbarcode-web-core/src/utils/types'
@Options({
  components: {
    TWPOBindingDialogComponent,
  },
  name: 'TWPOMainComponent',
  inheritAttrs: false,
})
export default class TWPOMainComponent extends mixins(MainComponentMixin) {
  get displayItems(): ITWProcess[] {
    return this.$store.getters.getPOProcessList || []
  }

  get bindingSetting(): IBindingSetting {
    return cloneDeep(this.$store.state.tradeWaltz.bindingSettings.reqPOBindingSettings)
  }
}
</script>
<style lang="scss" scoped>
@import './src/assets/css/mixins.scss';
</style>
