import { render } from "./BarcodeImport.vue?vue&type=template&id=dbc550f4&scoped=true"
import script from "./BarcodeImport.vue?vue&type=script&lang=ts"
export * from "./BarcodeImport.vue?vue&type=script&lang=ts"

import "./BarcodeImport.vue?vue&type=style&index=0&id=dbc550f4&lang=css"
import "./BarcodeImport.vue?vue&type=style&index=1&id=dbc550f4&lang=scss"
import "./BarcodeImport.vue?vue&type=style&index=2&id=dbc550f4&lang=scss&scoped=true"
script.render = render
script.__scopeId = "data-v-dbc550f4"

export default script