
import { Options, Vue } from 'vue-class-component'
import { Prop } from 'vue-property-decorator'

@Options({
  emits: ['update:modelValue'],
  name: 'InputOnlyNumberMixin',
})
export default class InputOnlyNumberMixin extends Vue {
  @Prop({ type: String }) readonly size = 'medium'
  @Prop({ type: String }) readonly classes?: string
  @Prop({ type: String }) readonly name?: string
  @Prop({ type: String }) readonly id?: string
  @Prop({ type: String }) readonly placeholder?: string

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  handleKeydown(event: any, value: string) {
    const actionKeys = [65, 66, 67, 68, 90]
    const keyCode = event.which ?? event.keyCode
    if (event.shiftKey === true) {
      if (event.preventDefault) {
        event.preventDefault()
      }
    }
    if (keyCode === 190 || keyCode === 110) {
      if (value.indexOf('.') > -1) {
        event.preventDefault()
      }
      if (this.precision !== 0) {
        return
      }
    }
    if (event.metaKey === true || event.ctrlKey === true) {
      if (!actionKeys.includes(keyCode)) event.preventDefault()
    } else {
      if (!((keyCode >= 48 && keyCode <= 58) || (keyCode >= 96 && keyCode <= 105) || keyCode === 8 || keyCode === 46)) {
        if (event.preventDefault) {
          event.preventDefault()
        }
      }
    }
  }
}
