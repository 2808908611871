
import SelectAutocomplete from '@/components/common/SelectAutocomplete.vue'
import { mixins, Options } from 'vue-class-component'
import TreeSelectComponent from '@/components/baseComponent/TreeSelectComponent.vue'
import { Prop } from 'vue-property-decorator'
import { SAVE_TW_BINDING_SETTING } from '@/store/actions'
import ValidateForm from '@/components/mixins/ValidateForm.vue'
import TWBindingDialogMixins from './TWBindingDialogMixins.vue'
import cloneDeep from 'lodash/cloneDeep'
import { IBindingSetting, IFieldOption } from 'smartbarcode-web-core/src/utils/types'
import { setTradeWaltzStoreBindingSetting } from '@/utils/helpers'

@Options({
  components: {
    SelectAutocomplete,
    TreeSelectComponent,
  },
  name: 'TWBindingDialogComponent',
  emits: ['update:isShowDialog'],
  inheritAttrs: false,
})
export default class TWBindingDialogComponent extends mixins(ValidateForm, TWBindingDialogMixins) {
  @Prop({ default: '' }) tradeWaltzStoreKey!: string
  @Prop({ default: [] }) TWSelectionOptions!: IFieldOption[]
  @Prop({ default: '' }) title!: string

  mounted() {
    const bindingSetting: IBindingSetting = cloneDeep(
      this.$store.state.tradeWaltz?.bindingSettings?.[this.tradeWaltzStoreKey]
    )
    this.bindingBarcodeModel = bindingSetting || {
      startTrackingPointKey: '',
      linkageGoodsLineBinding: [{ from: '', to: '' }],
    }
  }

  onSaveSetting() {
    // eslint-disable-next-line no-unused-expressions
    this.$refs?.[this.tradeWaltzStoreKey].validate(async (valid: string) => {
      if (valid) {
        this.$store.commit(SAVE_TW_BINDING_SETTING, { [this.tradeWaltzStoreKey]: this.bindingBarcodeModel })
        setTradeWaltzStoreBindingSetting(this.$store.state.tradeWaltz?.bindingSettings)
        this._isShowDialog = false
      } else {
        return false
      }
    })
  }
}
